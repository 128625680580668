export const getVariables = () => {

  return {
    API_URL: process.env.NEXT_PUBLIC_API_URL,
    URL_IMAGES: process.env.NEXT_PUBLIC_URL_IMAGES,
    URL_FILES: process.env.NEXT_PUBLIC_URL_FILES,
    GOOGLE_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
    APP_SITE_KEY: process.env.NEXT_PUBLIC_APP_SITE_KEY,
    APP_SECRET_KEY: process.env.NEXT_PUBLIC_APP_SECRET_KEY,
    TOKEN: process.env.NEXT_PUBLIC_TOKEN,
    SERVER_API_URL: process.env.SERVER_API_URL,
    CACHE_LEVEL_1: parseInt(process.env.CACHE_LEVEL_1) ?? 300,
    CACHE_LEVEL_2: parseInt(process.env.CACHE_LEVEL_2) ?? 600,
    CACHE_LEVEL_3: parseInt(process.env.CACHE_LEVEL_3) ?? 1200,
    NODE_ENV: process.env.NODE_ENV,
  }
}