import React, { useState, useEffect, useContext, createContext } from "react";
import api from "../api/api";


const ApiContext = createContext();

export function useApi() {
  return useContext(ApiContext);
}

export function ApiProvider({ children }) {

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getInitialTextos = () => {
    try {
      const storedTextos = localStorage.getItem("alltextos");
      if (storedTextos && isValidJSON(storedTextos)) {
        return JSON.parse(storedTextos);
      }
    } catch (error) {
      console.log('getInitialTextos:', error.message);
    }
    return [];
  }

  const getInitialTextosBig = () => {
    try {
      const storedTextos = localStorage.getItem("alltextosbig");
      if (storedTextos && isValidJSON(storedTextos)) {
        return JSON.parse(storedTextos);
      }
    } catch (error) {
      console.log('getInitialTextosBig:', error.message);
    }
    return [];
  }

  const getInitialSettings = () => {
    try {
      const storedSetting = localStorage.getItem("allsettings");
      if (storedSetting && isValidJSON(storedSetting)) {
        return JSON.parse(storedSetting);
      }
    } catch (error) {
      console.log('getInitialSettings:', error.message);
    }
    return [];
  }

  const getInitialCities = () => {
    try {
      const storedCities = localStorage.getItem("allciudades");
      if (storedCities && isValidJSON(storedCities)) {
        return JSON.parse(storedCities);
      }
    } catch (error) {
      console.log('getInitialCities:', error.message);
    }
    return [];
  }

  const getInitialTipoDocs = () => {
    try {

      const storedTipos = localStorage.getItem("alltipodoc");
      if (storedTipos && isValidJSON(storedTipos)) {
        return JSON.parse(storedTipos);
      }
    } catch (error) {
      console.log('getInitialTipoDocs: ', error.message);
    }
    return [];
  }

  const [currentTextos, setCurrentTextos] = useState(getInitialTextos);
  const [currentTextosBig, setCurrentTextosBig] = useState(getInitialTextosBig);
  const [currentSettings, setCurrentSettings] = useState(getInitialSettings);
  const [cities, setCities] = useState(getInitialCities);
  const [tipodocs, setTipodocs] = useState(getInitialTipoDocs);
  const [currentLocale, setCurrentLocale] = useState("es");
  const [currency, setCurrency] = useState('COP');
  const [scrollauntomatico, setScrollauntomatico] = useState(false);


  const updateCurrency = (newCurrency) => {
    setCurrency(newCurrency);
  };



  const getTranslation = (texto, tipo) => {
    if (texto) {
      let textos = texto?.translations?.find((text) => text.local === currentLocale);
      if (!textos && texto?.translations?.length > 0) {
        textos = texto?.translations[0];
      }
      return textos?.[tipo] ?? '';
    }
    return '';
  }

  const getTipoDocumentoTranslation = (item) => {
    const translations = item?.tipoDocumentoTranslations;
    if (translations) {
      if (translations?.length) return (translations?.find((text) => text.local === currentLocale)?.valor) ?? item?.tipo;
      else return item?.tipo;
    }
    return item?.tipo
  }

  const getTextos = async () => {
    try {
      const data = await api.get(`/textos`);
      return data.data['hydra:member'];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getTextosBig = async () => {
    try {
      const data = await api.get(`/texto_bigs`);
      return data.data['hydra:member'];
    } catch (error) {
      return [];
    }
  };

  const getCurrency = async (currency) => {
    try {
      const data = await api.post(`/change-currency`, { currency: currency });
      return data.data.valor;
    } catch (error) {
      return 1;
    }
  };

  const getSettings = async () => {
    try {
      const data = await api.get(`/settings`);
      return data.data['hydra:member'];
    } catch (error) {
      return [];
    }
  };


  const getCities = async () => {
    try {
      const data = await api.get(`/ciudads?visible=true&order[orden]=asc`);
      return data.data['hydra:member'];
    } catch (error) {
      return [];
    }
  };

  const getTipoDocumentos = async () => {
    try {
      const data = await api.get(`/tipo_documentos?visible=true`);
      return data.data['hydra:member'];
    } catch (error) {
      return [];
    }
  };

  const getTextoByKey = (key) => {

    if (currentTextos.length == 0)
      return [];
    let finded = currentTextos.find((text) => text.llave === key);
    if (finded) {
      let findedfinded = finded.translations.find((text) => text.local === currentLocale);
      if (findedfinded) {
        return findedfinded;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const getTextoBigByKey = (key) => {
    if (currentTextosBig.length == 0)
      return [];
    let finded = currentTextosBig.find((text) => text.llave === key);
    if (finded) {
      let findedfinded = finded.translations.find((text) => text.local === currentLocale);
      if (findedfinded) {
        return findedfinded;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const getSettingByKey = (key) => {

    if (currentSettings.length == 0)
      return [];
    let finded = currentSettings.find((setting) => setting.llave === key);
    if (finded) {
      return finded.valor;
    } else {
      return '';
    }
  };

  const getCiudades = async (home = 0) => {
    let orderedCities = cities;
    if (Array.isArray(orderedCities)) {
      orderedCities = orderedCities.sort((s1, s2) => s1.nombre.toLowerCase().localeCompare(s2.nombre.toLowerCase()))
    }
    if (!home) {
      return orderedCities;
    }else {
      let finded = orderedCities.filter((city) => city.home === true);
      if (finded) {
        return finded;
      } 
    }
    return [];
  };

  const getTipoDoc = async () => {
    if (tipodocs.length == 0) {
      return [];
    }
    return tipodocs

  };


  const convertCurrency = async (amount, moneda = 'COP') => {
    let currency = 1;
    if (moneda != 'COP') {
      currency = localStorage.getItem(moneda);
    }

    let priceLocale = Intl.NumberFormat('es-ES', {
      useGrouping: true,
    });

    amount = Math.round(amount * currency);
    let priceOutput = priceLocale.format(amount)
    if (moneda === 'EUR')
      return `€ ${priceOutput}`;
    return `$ ${priceOutput}`;

  };


  const getAllApis = async () => {


    let cacheTimes = localStorage.getItem("cache_info");
    let now = new Date();
    if (!cacheTimes)
      cacheTimes = now.getTime();
    let cacheTime = cacheTimes;
    try {
      console.log('cache apiss', now.getTime() - cacheTime);

      if (now.getTime() === cacheTime || !localStorage.getItem('actualizo-cache') || now.getTime() - cacheTime > 1800000) {
        let cargo = true;
        localStorage.setItem("cache_info", now.getTime());
        const [textosData, textosBigData, settinsgData, citiesData, tipodocData, usd, eur] = await Promise.all([
          getTextos(),
          getTextosBig(),
          getSettings(),
          getCities(),
          getTipoDocumentos(),
          getCurrency('USD'),
          getCurrency('EUR'),
        ]);


        setCurrentTextos(textosData);
        localStorage.setItem("alltextos", JSON.stringify(textosData));

        setCurrentTextosBig(textosBigData);
        localStorage.setItem("alltextosbig", JSON.stringify(textosBigData));

        setCurrentSettings(settinsgData);
        localStorage.setItem("allsettings", JSON.stringify(settinsgData));

        setCities(citiesData);
        localStorage.setItem("allciudades", JSON.stringify(citiesData));

        setTipodocs(tipodocData);
        localStorage.setItem("alltipodoc", JSON.stringify(tipodocData));

        localStorage.setItem('USD', usd);
        localStorage.setItem('EUR', eur);
        if (textosData.length == 0 || textosBigData.length == 0 || citiesData.length == 0 || settinsgData.length == 0 || tipodocData.length == 0) {
          cargo = false;
        }

        console.log(cargo, 'cache');
        if (cargo) {
          localStorage.setItem('actualizo-cache', 1);
        }
      } else {

        setCurrentTextos(getInitialTextos);
        setCurrentTextosBig(getInitialTextosBig);
        setCurrentSettings(getInitialSettings);
        setCities(getInitialCities);
        setTipodocs(getInitialTipoDocs);
      }
    } catch (error) {
      console.error("Error al cargar datos desde la API:", error);

    }
  };



  useEffect(() => {
    getAllApis();
  }, []);

  const value = {

    getTextoByKey,
    getTextoBigByKey,
    getSettingByKey,
    updateCurrency,
    convertCurrency,
    getCiudades,
    getTipoDoc,
    getTranslation,
    getTipoDocumentoTranslation,
    cities,
    tipodocs,
    currentTextos,
    currentSettings,
    setScrollauntomatico,
    scrollauntomatico,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
