import axios from 'axios';
import { getVariables } from '../config'

const { API_URL,TOKEN } = getVariables();

const api = axios.create({
  headers: {
  
    'Authorization': 'Bearer ' +TOKEN,
   
  },
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': 'Content-Type',
  baseURL: API_URL,
    
  });
  
  export default api;