import api from './api';
import { getVariables } from '../config'
import { notFound } from 'next/navigation';


export const getProyectos = async (query = '') => {
  try {
    const response = await api.get(`/proyectos?${query}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getProyecto = async (id) => {
  try {
    const response = await api.get(`/proyectos/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getProyectoForPage = async (id, setCallback) => {
  const response = await api.get(`/proyectos/${id}`);
  const project = response.data;
  if (project && !project?.esmacro) {
    setCallback(project);
    return project;
  }
  throw new Error("Project not found");
};

export const getMacroProyectoForPage = async (id, setCallback) => {
  const response = await api.get(`/proyectos/${id}`);
  const macroproject = response.data;
  if (macroproject && macroproject?.esmacro) {
    setCallback(macroproject);
    return macroproject;
  }
  throw new Error("Macroproject not found");
};

export function getApiDataItems(data, getOnlyFirst = false) {
  if (data['hydra:member']?.length > 0) {
    if (getOnlyFirst) {
      return data['hydra:member'][0];
    }
    return data['hydra:member'];
  }
}

export const getTipoInmuebles = async (query = '') => {
  try {
    const response = await api.get(`/tipo_inmuebles?${query}`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDocumentoInmuebles = async (query = '') => {
  try {
    const response = await api.get(`/documento_inmuebles?${query}`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getCategoriasDocumentoInmuebles = async (query = '') => {
  try {
    const response = await api.get(`/categoria_documentos?${query}`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getSubsidios = async () => {
  try {
    const response = await api.get(`/subsidios`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getSuperficies = async (query = '') => {
  try {
    const response = await api.get(`/superficies?${query}`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getEquipoVenta = async (query = '') => {
  try {
    const response = await api.get(`/colaboradors?${query}`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getMedicionesProyecto = async (query = '') => {
  try {
    const response = await api.get(`/medicion_relations?visible=1${query}&order[orden]=asc`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const getCertificacionesProyecto = async (query = '') => {
  try {
    const response = await api.get(`/certificacion_proyectos?visible=1${query}&order[orden]=asc`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getInmuebles = async (query) => {
  try {
    const response = await api.get(`/inmuebles?${query}`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getInmueblesAgrupacion = async (query) => {
  try {
    const response = await api.get(`/agrupacion_inmuebles?${query}`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getInmueble = async (id) => {
  try {
    const response = await api.get(`/inmuebles/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getSalaVentas = async (query = null) => {
  try {
    const response = await api.get(`/sala_ventas?${query ? query : ''}`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getSettingLlave = async (llave = null) => {
  try {
    const response = await api.get(`/settings?llave=${llave}`);
    return response.data['hydra:member']?.[0]?.valor;
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const geTramites = async (query = '') => {
  try {
    const response = await api.get(`/tramites?${query}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getCertificaciones = async (query = '') => {
  try {
    const response = await api.get(`/certificacions?${query}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const getEntidadesFinancieras = async (query = '') => {
  try {
    const response = await api.get(`/financiera_proyectos?${query}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const getFinancieras = async (query = '') => {
  try {
    const response = await api.get(`/entidad_financieras?${query}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const getUserDataVenta = (numeroIdentificacion) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("url", `V3/CBRClientes/API/Ventas/NumeroIdentificacion/${numeroIdentificacion}`);
      formData.append("method", "GET");
      const response = await api.post('/proxy', formData);
      resolve(response.data);
    } catch (error) {
      console.error(error);
      reject(null);
    }
  });
};

export const getMacroProyecto = (idMacro) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("url", `V3/CBRClientes/API/Proyectos/${idMacro}`);
      formData.append("method", "GET");
      const response = await api.post('/proxy', formData);
      resolve(response.data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const getEntidadesFinancierasSinco = (idEntidad) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("url", `V3/CBRClientes/API/EntidadesFinancieras/${idEntidad}`);
      formData.append("method", "GET");
      const response = await api.post('/proxy', formData);
      resolve(response.data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const getGenerarEstadoCuenta = async (data) => {
  try {
    const response = await api.post(`/cliente/estado-cuenta`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const getCertificado = async (data) => {
  try {
    const response = await api.post(`/generate-certificate`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getSimulacion = async (id) => {
  try {
    const response = await api.get(`/simuladors/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const GenerarSimulacion = async (data) => {
  try {
    const response = await api.post(`/simuladors`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getGarantiaIconos = async () => {
  try {
    const response = await api.get(`/garantia_iconos`);
    return response.data?.['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};
/**
 * @typedef {Object} ImageObject
 * @property {string} image
 */

/**
 * @typedef {Object} SeoObject
 * @property {string} titulo
 * @property {string} descripcion
 * @property {string} keywords
 * @property {string} canonical
 * @property {ImageObject|null} image
 */

/**
 * @function
 * @returns {Promise<SeoObject>} */
export const getSeo = async (url) => {

  const defaultValue = {
    titulo: "",
    descripcion: "",
    keywords: "",
    image: null
  };

  try {

    if (url === "/" || url.trim() === "") url = "home";
    url = url.replace(/^\/+/g, '');
    if (!url.includes('_next')) {
      const { data } = await api.get(`/seos/?url=${url}`);
      const seo = (data["hydra:member"]?.length && data["hydra:member"][0]) ?? defaultValue;
      const translation = (seo?.translations?.length && seo?.translations[0]) ?? defaultValue;
      translation.image = seo?.imagen ?? null;
      return translation;
    }
  } catch (error) {
    console.error(error);
  }
  return defaultValue;
}

export const getTipoUnidad = async (query = null) => {
  try {
    const response = await api.get(`/tipo_unidads?${query ? query : ''}`);
    return response.data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const getValidaSeccion = async (id) => {
  try {
    const response = await api.get(`/seccions/${id}`);
    const sec = response.data;

    if (sec) {
      if (sec?.visible) {
        return 1;
      }
    }

    return 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getValidaActivaSeccion = async (query = null) => {
  try {
    const response = await api.get(`/seccions?${query ? query : ''}`);
    return response.data['hydra:member'];
    return 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
};


export const ValidaCaptcha = async (token) => {
  try {
    const formData = new FormData();
    formData.append("url", `https://www.google.com/recaptcha/api/siteverify?secret=${getVariables().APP_SECRET_KEY}&response=${token}`);
    formData.append("tipo", 'externo');
    formData.append("method", "POST");
    const cliente = await api.post('/proxy', formData);
    return cliente?.data;

  } catch (error) {
    console.log(error);
    return [];
  }
}


export const getpreciosProyectos = async (query = null) => {
  try {
    const response = await api.get(`/precios-proyecto?${query ? query : ''}`);
    return response.data;

  } catch (error) {
    console.error(error);
    let valores = { minimo: '0', maximo: '0' };
    return valores;
  }
};

export const getAreasProyecto = async (query = null) => {
  try {
    const response = await api.get(`/superficies-proyecto?${query ? query : ''}`);
    return response.data;

  } catch (error) {
    console.error(error);
    let valores = { minimo: '0', maximo: '0' };
    return valores;
  }
};

export const getIconosProyecto = async (query = null) => {
  try {
    const response = await api.get(`/iconos-proyecto?${query ? query : ''}`);
    return response.data;

  } catch (error) {
    console.error(error);
    let valores = { minimo: '0', maximo: '0' };
    return valores;
  }
};
export const captchaclient = getVariables().APP_SITE_KEY;
export const url_image = getVariables().URL_IMAGES;
export const url_file = getVariables().URL_FILES;